.Price {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: baseline;
  width: 100%;
}

.Price__Text{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.Value {
  max-width: 470px;
  font-size: 85px;
  font-weight: 200;
}
.unit {
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (min-width: 768px) {
  .Price {
    width: 450px;
  }

  .Value {
    font-size: 118px;
  }
}
