.card {
  width: 100%;
  height: 260px;
  margin: 0 auto;
}

.bg-white {
  border-radius: 7px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.123);
}

.text-white {
    color: white;
}

.overflow {
  height: auto;
}

@media (min-width: 600px) {
  .card {
    min-width: 480px;
  }

}
