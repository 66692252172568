*{
  box-sizing: border-box;
}
body {
  margin: 0;
	padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
  font-weight: 600;
}
h1,p{
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  body{
  font-size: 30px;
  font-weight: 600;
  }
}

