.App {
  display: flex;
  flex-direction: column;
  background: #0d47a1;
  min-height: 100vh;
  overflow-y: overlay;
}
h1 {
  margin: 10px 5px;
  font-size: 40px;
  font-weight: 400;
}
h2 {
  font-size: 30px;
  margin: 0;
  margin-left: 10px;
  font-weight: 300;
}
.small-text {
  font-size: 16px;
}
.center {
  text-align: center;
}
.white {
  color: white;
}
.App-header {
  background-color: #1565c0;
}

.Wrapper {
  width: 100%;
}

.App-header .Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contenido {
  width: 100%;
  padding: 20px 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
}

@media (min-width: 600px) and (max-width: 979px) {
  .Wrapper {
    max-width: 94%;
    margin: 0 auto;
  }
}

@media (min-width: 980px) {
  body {
    font-size: 30px;
    font-weight: 600;
  }
  .Wrapper {
    max-width: 980px;
    margin: 0 auto;
  }
  h1 {
    margin: 10px 0px;
    font-size: 50px;
  }

  .contenido {
    margin: 20px auto;
    padding: 10px 0px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 260px 260px 360px;
    gap: 20px;
  }
  .item1 {
    grid-column: 1 / 2;
    grid-row: span 1 / 2;
  }
  .item2 {
    grid-column: 1 / 2;
    grid-row: span -3 / 2;
  }
  .item3 {
    grid-column: 1 / 2;
    grid-row: span -2 / 2;
  }
  .item4 {
    grid-column: 2 / 3;
    grid-row: -4 / -1;
  }
}
