.body{
display: flex;
flex-direction: column;
justify-content: space-between;
padding: 20px 0;

}

hr{
    width: 100%;
}