.wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.wrapper .lables{
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 14px;
}
.bar {
    width: 100%;
    height: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bar__inner {
    height: 5px;
    width: 100%;
    background-color: #92ccfa;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .bar__fill {
    height: 5px;
    width: 20%;
    transition: all 0.3s ease-out;
  }

  .bar__label {
    font-weight: bold;
    font-size: 0.5rem;
    text-align: center;
  }